.project-card {
  overflow: hidden;
}

.project-card img {
  width: 100%;
  border-radius: "5px 5px 0px 0px";
  -o-object-fit: cover;
  object-fit: cover;
  object-position: top;
  max-height: max(10rem, 10vh);
  mix-blend-mode: screen;
  filter: grayscale(1);
}

.experience-card-company {
  font-family: "Google Sans Regular";
  margin: 0;
  text-align: left;
}

.experience-card-company > a {
  position: relative;
  color: #8c8c8c;
  text-decoration: none;
}

.experience-card-company > a:hover {
  color: #8c8c8c;
}

.experience-card-company > a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.experience-card-company > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #8c8c8c;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.repo-stats {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 13px;
  color: rgb(106, 115, 125);
}

.repo-left-stat {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.language-color {
  width: 10px;
  height: 10px;
  background-color: blue;
  margin-right: 0.25rem;
  border-radius: 100%;
}

.repo-left-stat span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0.75rem;
}

.repo-name-div {
  display: flex;
  align-items: center;
}

.repo-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-name {
  white-space: nowrap;
  line-height: 1.1;
  text-overflow: ellipsis;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  margin: 0px;
}

.repo-star-svg {
  margin-right: 0.3rem;
}

.repo-description {
  word-wrap: break-word;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.repo-details {
  display: flex;
  flex-direction: row;
}

.repo-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

@media (max-width: 768px) {
  .repo-name {
    font-size: 16px;
  }

  .repo-description {
    font-size: 14px;
  }

  .repo-details {
    flex-direction: column;
  }

  .repo-creation-date {
    font-size: 14px;
  }
}
